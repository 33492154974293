declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

export const pageview = (url: string) => {
  if (!GTM_ID) return;
  window.dataLayer?.push({
    event: "pageview",
    page: url,
  });
};
