import axios from "axios";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { useRouter } from "next/router";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

const firebaseConfig = {
  apiKey: "AIzaSyA4xKEOWDY_6yWQ4xoKHsKfFhTUHoG1WP8",
  authDomain: "hair-abb.firebaseapp.com",
  projectId: "hair-abb",
  storageBucket: "hair-abb.appspot.com",
  messagingSenderId: "720071271546",
  appId: "1:720071271546:web:2cc7ed94efd74cf04547c3",
};

initializeApp(firebaseConfig);

type BoUserAuthContextType = {
  user: any;
  isSuperAdmin: boolean;
  login: (email: string, password: string) => any;
  logout: () => void;
};

const BoUserAuthContext = createContext<BoUserAuthContextType>(
  {} as BoUserAuthContextType
);

const getUserByFbId = async (id: string) => {
  try {
    const resp = await axios({
      method: "GET",
      url: `/api/backoffice-users?id=${id}`,
    });
    return resp.data;
  } catch (error) {
    console.log("error... getUserByFbId", error);
  }
};

const BoUserAuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<any>({ loading: true });
  const { push } = useRouter();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (fbUser) => {
      if (fbUser) {
        const user = await getUserByFbId(fbUser.uid);
        setUser({ ...user, loading: false });
      } else {
        setUser({ loading: false });
      }
    });
    return () => unsubscribe();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      setUser({ loading: true });
      const auth = getAuth();
      const credentials = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = await getUserByFbId(credentials.user.uid);
      setUser({ ...user, loading: false });
      if (!user?.company && user?.access_lvl === 9) return push("/superadmin");
      push("/admin");
    } catch (e: any) {
      setUser({ loading: false });
      return {
        error: e?.code || "Något gick fel. Försök igen senare.",
      };
    }
  };

  const logout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      push("/");
      setUser({ loading: false });
    } catch (e) {
      console.log(e);
    }
  };

  const values = { user, isSuperAdmin: user.access_lvl === 9 };
  const methods = { login, logout };

  return (
    <BoUserAuthContext.Provider value={{ ...values, ...methods }}>
      {children}
    </BoUserAuthContext.Provider>
  );
};

const useBoUserAuthContext = () => useContext(BoUserAuthContext);

export { BoUserAuthContextProvider, useBoUserAuthContext };
export default BoUserAuthContext;
